module.exports = [{
      plugin: require('C:/Development/spherebox-web/web/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/Development/spherebox-web/web/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/Development/spherebox-web/web/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":500,"sizeByPixelDensity":true,"linkImagesToOriginal":false},
    },{
      plugin: require('C:/Development/spherebox-web/web/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
